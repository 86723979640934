export enum InvitationType {
    Member = "Member",
    Company = "Company",
    Investor = "Investor",
    Admin = "Admin"
}

export interface InvitationTokenPayload {
    email: string;
    type: InvitationType;
}
