import { AdminIdentityContext } from "App";
import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import { TruncatedTextField } from "components/truncated-text-field";
import { investorContactTypes } from "constants/contractTypes";
import { useContext } from "react";
import {
    Datagrid,
    EmailField,
    FunctionField,
    List,
    SearchInput,
    SelectInput,
    TextField
} from "react-admin";

const postFilters = [
    <SearchInput
        source="email"
        alwaysOn
        placeholder="Email contains"
        resettable
    />,
    <SearchInput
        source="contactName"
        alwaysOn
        placeholder="Name contains"
        resettable
    />,
    <SearchInput
        source="companyName"
        alwaysOn
        placeholder="Company contains"
        resettable
    />,
    <SelectInput
        source="contractType"
        choices={investorContactTypes}
        alwaysOn
    />
];

export const InvestorsList = (props: any) => {
    const isSuperAdmin = useContext(AdminIdentityContext);

    return (
        <List
            {...props}
            sort={{ field: undefined }}
            filters={postFilters}
            actions={<ListActionsToolbarWithoutFilters />}
            perPage={50}
        >
            <Datagrid
                rowClick="edit"
                bulkActionButtons={
                    <BulkDeleteButtons isSuperAdmin={isSuperAdmin} />
                }
            >
                <EmailField source="email" />
                <FunctionField
                    label="Name"
                    render={(record: any) => {
                        const contact = record.contact;
                        return `${contact.firstName} ${contact.lastName}`;
                    }}
                    sortable={false}
                />
                <TextField
                    source="contact.role"
                    label="Role"
                    sortable={false}
                />
                <TextField source="companyName" label="Company" />
                <TextField
                    source="contract.type"
                    label="Contract"
                    sortable={false}
                />
                <FunctionField
                    label="Details"
                    render={(record: any) => {
                        const details = record.contract.details;
                        return <TruncatedTextField text={details} />;
                    }}
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};
