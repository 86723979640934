import { GradientPageWrapper } from "styled-components/GradientPageWrapper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { PaperFormWrapper } from "styled-components/PaperFormWrapper";
import { ThemeProvider } from "react-admin";
import { customRadiantLightTheme } from "themes/radiantLightTheme";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { EMPTY_HELPER_TEXT, SNACKBAR_SCREEN_TIME } from "constants/general";
import { useResetPassword } from "./useResetPassword";

const ResetPassword = () => {
    const {
        errors,
        handleOnSnackbarClose,
        handleSubmit,
        isSomethingWrong,
        register,
        isLoading,
        email
    } = useResetPassword();

    return (
        <GradientPageWrapper>
            <ThemeProvider theme={customRadiantLightTheme}>
                <Snackbar
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                    open={isSomethingWrong}
                    autoHideDuration={SNACKBAR_SCREEN_TIME}
                    onClose={handleOnSnackbarClose}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: "100%" }}
                    >
                        Something went wrong...
                    </Alert>
                </Snackbar>
                <PaperFormWrapper>
                    {isLoading || !email ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            <div
                                style={{
                                    fontSize: "16px",
                                    lineHeight: "23px"
                                }}
                            >
                                <span>Reset account password for </span>
                                <div style={{ wordBreak: "break-all" }}>
                                    {email}
                                </div>
                            </div>
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "12px"
                                }}
                            >
                                <TextField
                                    label="Password *"
                                    type="password"
                                    {...register("password")}
                                    helperText={
                                        errors.password?.message ||
                                        EMPTY_HELPER_TEXT
                                    }
                                    autoComplete="new-password"
                                    error={Boolean(errors.password)}
                                />
                                <TextField
                                    label="Repeat password *"
                                    type="password"
                                    {...register("repeatPassword")}
                                    helperText={
                                        errors.repeatPassword?.message ||
                                        EMPTY_HELPER_TEXT
                                    }
                                    autoComplete="new-password"
                                    sx={{ marginBottom: "20px" }}
                                    error={Boolean(errors.repeatPassword)}
                                />
                                <Button variant="contained" type="submit">
                                    Submit
                                </Button>
                            </Box>
                        </>
                    )}
                </PaperFormWrapper>
            </ThemeProvider>
        </GradientPageWrapper>
    );
};

export default ResetPassword;
