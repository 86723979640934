import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import API from "api/backend";

import { forgotPasswordFormSchema } from "schemas/forgotPasswordFormSchema";
import { resetPasswordFormSchema } from "schemas/resetPasswordFormSchema";

type FormInputs = {
    email: string;
    password: string;
    repeatPassword: string;
};

export const useResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [isSomethingWrong, setIsSomethingWrong] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState<string | null>(null);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<FormInputs>({
        mode: "onChange",
        resolver: joiResolver(resetPasswordFormSchema)
    });

    const handleOnSnackbarClose = () => {
        setIsSomethingWrong(false);
    };

    const onSubmit = async ({ password, repeatPassword }: FormInputs) => {
        try {
            await API.Auth.resetPassword({
                password,
                token: token!
            });

            const { ok } = await API.Auth.signIn({
                email: email!,
                password
            });

            if (ok) navigate("/", { replace: true });
            else {
                setIsSomethingWrong(true);
            }
        } catch (error) {
            setIsSomethingWrong(true);
        }
    };

    useEffect(() => {
        (async () => {
            if (token) {
                setIsLoading(true);
                try {
                    const { ok, data } =
                        await API.Auth.checkPasswordToken(token);

                    if (ok) {
                        setEmail(data.email);
                    } else {
                        navigate("/", { replace: true });
                    }
                } catch (err: any) {
                    console.log(err);
                    navigate("/", { replace: true });
                } finally {
                    setIsLoading(false);
                }
            } else navigate("/", { replace: true });
        })();
    }, []);

    return {
        isSomethingWrong,
        handleOnSnackbarClose,
        handleSubmit: handleSubmit(onSubmit),
        errors,
        register,
        email,
        isLoading
    };
};
