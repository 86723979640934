import { BulkDeleteButton } from "react-admin";

const warning = "warning" as const;

type BulkDeleteButtonsProps = {
    isSuperAdmin?: boolean;
};
export const BulkDeleteButtons = ({ isSuperAdmin }: BulkDeleteButtonsProps) => {
    return isSuperAdmin ? (
        <BulkDeleteButton
            size="medium"
            confirmTitle="Are you sure? This action is irreversible!"
            confirmContent=""
            confirmColor={warning}
            mutationMode="pessimistic"
        />
    ) : null;
};
