import { AdminIdentityContext } from "App";
import { ShowAboveClickableRow } from "components/show-above-clickable-row";
import { TruncatedTextField } from "components/truncated-text-field";
import { useContext } from "react";
import {
    Datagrid,
    FunctionField,
    Identifier,
    List,
    RaRecord,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField
} from "react-admin";

const questionsFilter = [
    <SearchInput source="key" alwaysOn placeholder="Key contains" resettable />,
    <SearchInput
        source="text"
        alwaysOn
        placeholder="Text contains"
        resettable
    />,
    <ReferenceInput
        source="surveyId"
        reference="surveys"
        sort={{ order: "ASC", field: "" }}
        alwaysOn
    >
        <SelectInput
            alwaysOn
            optionText={(survey) => {
                return `${survey.name}`;
            }}
        />
    </ReferenceInput>
];

export const QuestionsList = (props: any) => {
    const isSuperAdmin = useContext(AdminIdentityContext);

    return (
        <List
            {...props}
            sort={{ field: undefined }}
            perPage={50}
            filters={questionsFilter}
        >
            <Datagrid rowClick="edit" bulkActionButtons={false}>
                <TextField source="key" sortable={false} />

                <ReferenceField
                    source="surveyId"
                    reference="surveys"
                    sortable={false}
                >
                    <ShowAboveClickableRow>
                        <FunctionField
                            render={(record: any) => {
                                return <span>{record?.name}</span>;
                            }}
                        />
                    </ShowAboveClickableRow>
                </ReferenceField>

                <TextField source="order" sortable={false} />

                <TextField
                    source="pattern.type"
                    label="Type"
                    sortable={false}
                />

                <FunctionField
                    source="text"
                    render={(record: RaRecord<Identifier>) => (
                        <TruncatedTextField text={record.text} />
                    )}
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};
