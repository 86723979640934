import { AdminIdentityContext } from "App";
import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { CompanyNameField } from "components/company/CompanyNameField";
import { CopyToClipboardButton } from "components/copy-to-clipboard-button";
import { MemberNameField } from "components/member/MemberNameField";
import { ShowAboveClickableRow } from "components/show-above-clickable-row";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import { superAdminAndFounderOptions } from "constants/founderAndSuperTypes";
import { useContext } from "react";
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    EmailField,
    FunctionField,
    Identifier,
    List,
    RaRecord,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField
} from "react-admin";

const membersFilter = [
    <SearchInput
        source="email"
        alwaysOn
        placeholder="Email contains"
        resettable
    />,
    <SearchInput
        source="name"
        alwaysOn
        placeholder="Name contains"
        resettable
    />,
    <ReferenceInput
        source="companyId"
        reference="companies"
        sort={{ order: "ASC", field: "" }}
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={(company) => ({ name: company })}
            optionText="name"
        />
    </ReferenceInput>,
    <SelectInput
        source="isSuper"
        choices={superAdminAndFounderOptions}
        optionValue="value"
        optionText="id"
        alwaysOn
    />,
    <SelectInput
        source="isFounder"
        choices={superAdminAndFounderOptions}
        optionValue="value"
        optionText="id"
        alwaysOn
    />
];

export const MembersList = (props: any) => {
    const isSuperAdmin = useContext(AdminIdentityContext);

    return (
        <List
            {...props}
            sort={{ field: undefined }}
            perPage={50}
            actions={<ListActionsToolbarWithoutFilters />}
            filters={membersFilter}
        >
            <Datagrid
                rowClick="edit"
                bulkActionButtons={
                    <BulkDeleteButtons isSuperAdmin={isSuperAdmin} />
                }
            >
                <EmailField source="email" />

                <FunctionField
                    label="Name"
                    render={(record: any) => {
                        return <MemberNameField data={record} />;
                    }}
                />

                <FunctionField
                    source="linkedin"
                    render={(record: RaRecord<Identifier>) => (
                        <CopyToClipboardButton
                            textToCopy={record.linkedin}
                            successMessage="URL is copied!"
                        />
                    )}
                    sortable={false}
                />

                <TextField source="role" />
                <ReferenceField
                    label="Company"
                    source="company"
                    reference="companies"
                    sortable={false}
                >
                    <ShowAboveClickableRow>
                        <FunctionField
                            render={(record: any) => {
                                return <CompanyNameField data={record} />;
                            }}
                        />
                    </ShowAboveClickableRow>
                </ReferenceField>
                <BooleanField source="isFounder" />
                <FunctionField
                    source="sharesAmount"
                    render={(record: RaRecord<Identifier>) => {
                        const shares =
                            typeof record.sharesAmount === "number"
                                ? `${record.sharesAmount}%`
                                : "none";
                        return <span>{shares}</span>;
                    }}
                />
                <BooleanField source="isSuper" label="Main contact" />
            </Datagrid>
        </List>
    );
};
