import { Create, SelectInput, SimpleForm, required } from "react-admin";
import Typography from "@mui/material/Typography";
import { DefaultTextInput } from "components/default-text-input";

export const InvestorInvitationCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Send investor invitation
                </Typography>

                <DefaultTextInput
                    source="email"
                    type="email"
                    validate={[required()]}
                />

                <SelectInput
                    source="contract.type"
                    choices={[
                        { id: "free", name: "Free" },
                        { id: "paid", name: "Paid" }
                    ]}
                />

                <DefaultTextInput source="contract.details" multiline />
            </SimpleForm>
        </Create>
    );
};
