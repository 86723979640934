import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import {
    Datagrid,
    EmailField,
    List,
    SearchInput,
    TextField
} from "react-admin";

const postFilters = [<SearchInput source="email" alwaysOn />];

export const AdminsList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid
            rowClick="edit"
            bulkActionButtons={<BulkDeleteButtons isSuperAdmin />}
        >
            <EmailField source="email" />
            <TextField source="name" />
            <TextField source="isSuper" />
        </Datagrid>
    </List>
);
