import { AdminIdentityContext } from "App";
import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import { TruncatedTextField } from "components/truncated-text-field";
import { useContext } from "react";
import {
    Datagrid,
    FunctionField,
    Identifier,
    List,
    RaRecord,
    SearchInput,
    TextField
} from "react-admin";

const questionsFilter = [
    <SearchInput source="key" alwaysOn placeholder="Key contains" resettable />,
    <SearchInput
        source="text"
        alwaysOn
        placeholder="Text contains"
        resettable
    />
];

export const ConclusionsList = (props: any) => {
    const isSuperAdmin = useContext(AdminIdentityContext);
    return (
        <List
            {...props}
            sort={{ field: undefined }}
            perPage={50}
            actions={<ListActionsToolbarWithoutFilters />}
            filters={questionsFilter}
        >
            <Datagrid rowClick="edit" bulkActionButtons={false}>
                <TextField source="id" sortable={false} label="Key" />

                <TextField source="lowTitle" sortable={false} />

                <FunctionField
                    source="lowText"
                    render={(record: RaRecord<Identifier>) => (
                        <TruncatedTextField text={record.lowText} />
                    )}
                    sortable={false}
                />

                <TextField source="mediumTitle" sortable={false} />

                <FunctionField
                    source="mediumText"
                    render={(record: RaRecord<Identifier>) => (
                        <TruncatedTextField text={record.mediumText} />
                    )}
                    sortable={false}
                />

                <TextField source="highTitle" sortable={false} />

                <FunctionField
                    source="highText"
                    render={(record: RaRecord<Identifier>) => (
                        <TruncatedTextField text={record.highText} />
                    )}
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};
