import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import API from "api/backend";

import { forgotPasswordFormSchema } from "schemas/forgotPasswordFormSchema";

type FormInputs = {
    email: string;
};

export const useForgotPassword = () => {
    const navigate = useNavigate();
    const [isSomethingWrong, setIsSomethingWrong] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormInputs>({
        resolver: joiResolver(forgotPasswordFormSchema)
    });

    const handleOnSnackbarClose = () => {
        setIsSomethingWrong(false);
    };

    const onSubmit = async ({ email }: FormInputs) => {
        const { ok } = await API.Auth.forgotPassword({ email });

        if (ok) navigate("/login", { replace: true });
        else {
            setIsSomethingWrong(true);
        }
    };

    return {
        isSomethingWrong,
        handleOnSnackbarClose,
        handleSubmit: handleSubmit(onSubmit),
        errors,
        register
    };
};
