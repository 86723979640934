import { AdminNameField } from "components/admin/AdminNameField";
import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { CopyToClipboardButton } from "components/copy-to-clipboard-button";
import { ReferenceFunctionField } from "components/reference-function-field";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import { INVITATION_PENDING_OPTION } from "constants/invitationStatusOptions";
import {
    Datagrid,
    DateField,
    FunctionField,
    Identifier,
    List,
    RaRecord,
    SearchInput,
    TextField
} from "react-admin";
import Utils from "utils";
import { cursorDefaultStyles } from "utils/RowClickFalseStyles";

const postFilters = [<SearchInput source="email" alwaysOn />];

export const AdminInvitationList = (props: any) => {
    return (
        <List
            {...props}
            sort={{ field: undefined }}
            actions={<ListActionsToolbarWithoutFilters isCreateIncluded />}
            filters={postFilters}
            perPage={50}
        >
            <Datagrid
                rowClick="edit"
                sx={cursorDefaultStyles}
                bulkActionButtons={<BulkDeleteButtons isSuperAdmin />}
            >
                <TextField source="email" />
                <TextField source="status" sortable={false} />
                <FunctionField
                    label="Invited By"
                    render={() => (
                        <ReferenceFunctionField
                            idPath="inviterId"
                            source="admins"
                            render={AdminNameField}
                        />
                    )}
                    sortable={false}
                />
                <DateField
                    source="createdAt"
                    options={Utils.DateFieldOptions.DAY_MONTH_YEAR}
                />
                <FunctionField
                    label="Admin"
                    render={() => (
                        <ReferenceFunctionField
                            idPath="inviteeId"
                            source="admins"
                            render={AdminNameField}
                        />
                    )}
                    sortable={false}
                />
                <DateField
                    source="usedAt"
                    options={Utils.DateFieldOptions.DAY_MONTH_YEAR}
                />
                <FunctionField
                    source="url"
                    render={(record: RaRecord<Identifier>) => (
                        <CopyToClipboardButton
                            textToCopy={record.url}
                            successMessage="URL is copied!"
                            isDisabled={Boolean(
                                record.status !== INVITATION_PENDING_OPTION.id
                            )}
                        />
                    )}
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};
