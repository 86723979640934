import {
    Route,
    Routes,
    Navigate,
    BrowserRouter as Router
} from "react-router-dom";
import { FC, createContext } from "react";
import { Admin, Resource } from "react-admin";

import Invitation from "routes/invitation-page";
import authProvider from "providers/auth.provider";
import dataProvider from "providers/data.provider";
import { AdminsList, AdminEdit } from "menu/admins";
import { InvestorEdit, InvestorsList } from "menu/investors";
import {
    InvestorInvitationCreate,
    InvestorInvitationList
} from "menu/investor-invitations";

import { CompanyEdit, CompanyList } from "menu/companies";
import { MemberEdit, MembersList } from "menu/members";
import { CompanyInvitationsList } from "menu/company-invitations";
import { MemberInvitationList } from "menu/member-invitations";
import {
    AdminInvitationCreate,
    AdminInvitationList
} from "menu/admin-invitations";
import { useAdminIdentity } from "hooks/useAdminIdentity";
import { QuestionEdit, QuestionsList } from "menu/questions";
import { ReactComponent as AddBusiness } from "./icons/add-business.svg";
import { ReactComponent as DomainAdd } from "./icons/domain-add.svg";
import { ReactComponent as Domain } from "./icons/domain.svg";
import { ReactComponent as EncryptedAddCircle } from "./icons/encrypted-add-circle.svg";
import { ReactComponent as PersonAdd } from "./icons/person-add.svg";
import { ReactComponent as Person } from "./icons/person.svg";
import { ReactComponent as Quiz } from "./icons/quiz.svg";
import { ReactComponent as ShieldPerson } from "./icons/shield-person.svg";
import { ReactComponent as Store } from "./icons/store.svg";
import { customRadiantLightTheme } from "themes/radiantLightTheme";
import { ConclusionEdit, ConclusionsList } from "menu/conclusions";
import Login from "routes/Login";
import ForgotPassword from "routes/forgot-password";
import ResetPassword from "routes/reset-password";
export const AdminIdentityContext = createContext(false);

const AdminComponent: FC = () => {
    const { isSuperAdmin } = useAdminIdentity();

    return (
        <AdminIdentityContext.Provider value={isSuperAdmin}>
            <Admin
                loginPage={Login}
                authProvider={authProvider}
                dataProvider={dataProvider}
                theme={customRadiantLightTheme}
            >
                {isSuperAdmin && (
                    <>
                        <Resource
                            name="admins"
                            list={AdminsList}
                            edit={AdminEdit}
                            icon={ShieldPerson}
                        />
                        <Resource
                            name="admin-invitations"
                            list={AdminInvitationList}
                            create={AdminInvitationCreate}
                            icon={EncryptedAddCircle}
                        />
                    </>
                )}

                <Resource
                    name="investors"
                    list={InvestorsList}
                    edit={InvestorEdit}
                    icon={Domain}
                />

                <Resource
                    name="investor-invitations"
                    list={InvestorInvitationList}
                    create={InvestorInvitationCreate}
                    icon={DomainAdd}
                />

                <Resource
                    name="companies"
                    list={CompanyList}
                    edit={CompanyEdit}
                    icon={Store}
                />

                <Resource
                    name="company-invitations"
                    list={CompanyInvitationsList}
                    icon={AddBusiness}
                />

                <Resource
                    name="members"
                    list={MembersList}
                    edit={MemberEdit}
                    icon={Person}
                />

                <Resource
                    name="member-invitations"
                    list={MemberInvitationList}
                    icon={PersonAdd}
                />

                <Resource
                    name="questions"
                    list={QuestionsList}
                    edit={QuestionEdit}
                    icon={Quiz}
                />

                <Resource
                    name="conclusions"
                    list={ConclusionsList}
                    edit={ConclusionEdit}
                    icon={Store}
                />
            </Admin>
        </AdminIdentityContext.Provider>
    );
};

const App: FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/invitation/:token" Component={Invitation} />
                <Route path="/forgot-password" Component={ForgotPassword} />
                <Route
                    path="/reset-password/:token"
                    Component={ResetPassword}
                />
                <Route path="/*" Component={AdminComponent} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
};

export default App;
