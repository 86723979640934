import { useCitySearch } from "hooks/useCitySearch";
import { useCountries } from "hooks/useCountries";
import { useState } from "react";
import { AutocompleteInput } from "react-admin";
import { useFormContext } from "react-hook-form";

export const LocationSection = ({
    citySource,
    countrySource
}: {
    citySource: string;
    countrySource: string;
}) => {
    const { watch, setValue } = useFormContext();
    const { countries } = useCountries();
    const [citySearch, setCitySearch] = useState<string>("");
    const country = watch(countrySource);
    const { filteredCitiesList } = useCitySearch(country, citySearch);

    const handleCountryChange = (selectedCountry: string) => {
        if (!selectedCountry) setValue(citySource, null);
    };

    const handleCitySearchChange = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        const { value } = e.target as HTMLInputElement;
        setCitySearch(value);
    };

    return (
        <>
            <AutocompleteInput
                sx={{
                    "& .MuiFormControl-root": { margin: "0px" },
                    minWidth: "400px"
                }}
                source="location.country"
                optionValue="id"
                optionText="name"
                onChange={handleCountryChange}
                choices={countries}
            />

            <AutocompleteInput
                sx={{
                    "& .MuiFormControl-root": { margin: "0px" },
                    minWidth: "400px"
                }}
                source={citySource}
                optionValue="name"
                optionText="name"
                onKeyUp={handleCitySearchChange}
                readOnly={!country}
                choices={filteredCitiesList}
            />
        </>
    );
};
