export const memberRoleOptions = [
    {
        id: "CEO",
        name: "CEO"
    },
    {
        id: "CTO",
        name: "CTO"
    },
    {
        id: "CPOProduct",
        name: "CPO (Product)"
    },
    {
        id: "CPOPeople",
        name: "CPO (People)"
    },
    {
        id: "CMO",
        name: "CMO"
    },
    {
        id: "CFO",
        name: "CFO"
    },
    {
        id: "CCO",
        name: "CCO"
    },
    {
        id: "COO",
        name: "COO"
    },
    {
        id: "Other",
        name: "Other"
    }
];
