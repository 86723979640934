import API from "api";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useAdminIdentity = () => {
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const location = useLocation();

    const getAdminIdentity = async () => {
        const { data } = await API.Backend.Me.getMe();
        if (data.auth) setIsSuperAdmin(data.isSuper);
    };

    useEffect(() => {
        if (isLoggedIn) {
            getAdminIdentity();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (location.pathname === "/login") {
            setIsLoggedIn(false);
        } else if (!isLoggedIn) {
            setIsLoggedIn(true);
        }
    }, [location.pathname]);

    return { isSuperAdmin };
};
