import { CreateButton, ExportButton, TopToolbar } from "react-admin";

type ListActionsToolbarWithoutFiltersProps = {
    isCreateIncluded?: boolean;
};

export const ListActionsToolbarWithoutFilters = ({
    isCreateIncluded
}: ListActionsToolbarWithoutFiltersProps) => (
    <TopToolbar>
        {isCreateIncluded && <CreateButton />}
        <ExportButton />
    </TopToolbar>
);
