import {
    NAME_EMPTY,
    PASSWORD_EMPTY,
    PASSWORD_MATCH,
    PASSWORD_MIN
} from "constants/errorMessages";
import Joi from "joi";

export const invitationFormSchema = Joi.object({
    name: Joi.string().messages({
        "string.empty": NAME_EMPTY
    }),
    password: Joi.string().min(6).messages({
        "string.empty": PASSWORD_EMPTY,
        "string.min": PASSWORD_MIN
    }),
    repeatPassword: Joi.string()
        .min(6)
        .custom((value, helpers) => {
            const password = helpers.state.ancestors?.[0]?.password;
            if (value !== password) return helpers.error("any.only");
            return value;
        })
        .messages({
            "string.empty": PASSWORD_EMPTY,
            "any.only": PASSWORD_MATCH,
            "string.min": PASSWORD_MIN
        })
});
