import { useEffect, useState } from "react";
import { maxLength, useInput, useRecordContext } from "react-admin";
import Typography from "@mui/material/Typography";
import { useFormContext } from "react-hook-form";
import { DefaultTextInput } from "components/default-text-input";

export const MemberMotivationSection = () => {
    const record = useRecordContext();
    const { field: isFounder } = useInput({ source: "isFounder" });
    const { setValue } = useFormContext();
    const { founderWhy, startedCompanyWhy } = record;

    const [currentFounderWhy, setCurrentFounderWhy] = useState<
        string | undefined | null
    >("");

    const [currentStartedCompanyWhy, setCurrentStartedCompanyWhy] = useState<
        string | undefined | null
    >("");

    const handleFounderWhyChange = (e: any) => {
        const newValue = e.target.value;
        setCurrentFounderWhy(newValue);
    };

    const handleStartedCompanyWhyChange = (e: any) => {
        const newValue = e.target.value;
        setCurrentStartedCompanyWhy(newValue);
    };

    useEffect(() => {
        setCurrentStartedCompanyWhy(startedCompanyWhy);
        setCurrentFounderWhy(founderWhy);
    }, []);

    useEffect(() => {
        if (isFounder.value) {
            setValue("founderWhy", currentFounderWhy);
            setValue("startedCompanyWhy", currentStartedCompanyWhy);
        } else {
            setValue("founderWhy", "");
            setValue("startedCompanyWhy", "");
        }
    }, [isFounder.value]);

    return (
        isFounder.value && (
            <>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Co-founder details
                </Typography>

                <DefaultTextInput
                    source="founderWhy"
                    onChange={handleFounderWhyChange}
                    validate={maxLength(3000)}
                    multiline
                    label="General motivation"
                />

                <DefaultTextInput
                    source="startedCompanyWhy"
                    onChange={handleStartedCompanyWhyChange}
                    validate={maxLength(3000)}
                    multiline
                    label="This company motivation"
                />
            </>
        )
    );
};
