import { AdminIdentityContext } from "App";
import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { CompanyNameField } from "components/company/CompanyNameField";
import { CopyToClipboardButton } from "components/copy-to-clipboard-button";
import { MemberNameField } from "components/member/MemberNameField";
import { ShowAboveClickableRow } from "components/show-above-clickable-row";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import {
    INVITATION_PENDING_OPTION,
    invitationStatusOptions
} from "constants/invitationStatusOptions";
import { useContext } from "react";
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    EmailField,
    FunctionField,
    Identifier,
    List,
    RaRecord,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField
} from "react-admin";
import Utils from "utils";
import { cursorDefaultStyles } from "utils/RowClickFalseStyles";

const memberInvitationFilter = [
    <SearchInput source="email" alwaysOn />,
    <SelectInput source="status" choices={invitationStatusOptions} alwaysOn />,
    <ReferenceInput
        source="inviterId"
        reference="companies"
        sort={{ order: "ASC", field: "" }}
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={(company) => ({ name: company })}
            optionText="name"
        />
    </ReferenceInput>
];

export const MemberInvitationList = (props: any) => {
    const isSuperAdmin = useContext(AdminIdentityContext);

    return (
        <List
            {...props}
            sort={{ field: undefined }}
            perPage={50}
            actions={<ListActionsToolbarWithoutFilters />}
            filters={memberInvitationFilter}
        >
            <Datagrid
                rowClick="false"
                sx={cursorDefaultStyles}
                bulkActionButtons={
                    <BulkDeleteButtons isSuperAdmin={isSuperAdmin} />
                }
            >
                <EmailField source="email" />
                <TextField source="status" sortable={false} />

                <ReferenceField
                    label="Company"
                    source="inviterId"
                    reference="companies"
                    sortable={false}
                >
                    <ShowAboveClickableRow>
                        <FunctionField
                            render={(record: any) => (
                                <CompanyNameField data={record} />
                            )}
                        />
                    </ShowAboveClickableRow>
                </ReferenceField>

                <DateField
                    source="createdAt"
                    options={Utils.DateFieldOptions.DAY_MONTH_YEAR}
                />

                <ReferenceField
                    label="Member"
                    source="inviteeId"
                    reference="members"
                    sortable={false}
                >
                    <ShowAboveClickableRow>
                        <FunctionField
                            render={(record: any) => (
                                <MemberNameField data={record} />
                            )}
                        />
                    </ShowAboveClickableRow>
                </ReferenceField>

                <DateField
                    source="usedAt"
                    options={Utils.DateFieldOptions.DAY_MONTH_YEAR}
                />
                <FunctionField
                    source="url"
                    render={(record: RaRecord<Identifier>) => (
                        <CopyToClipboardButton
                            textToCopy={record.url}
                            successMessage="URL is copied!"
                            isDisabled={Boolean(
                                record.status !== INVITATION_PENDING_OPTION.id
                            )}
                        />
                    )}
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};
