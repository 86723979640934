import * as Me from "./entities/me";
import * as Auth from "./entities/auth";
import * as Invitation from "./entities/invitation";
import * as Resource from "./entities/resource";

const Backend = {
    Me,
    Auth,
    Invitation,
    Resource
};

export default Backend;
