import { CompanyNameField } from "components/company/CompanyNameField";
import { CopyToClipboardButton } from "components/copy-to-clipboard-button";
import { InvestorNameField } from "components/investor/InvestorNameField";
import { ShowAboveClickableRow } from "components/show-above-clickable-row";
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    EmailField,
    FunctionField,
    Identifier,
    List,
    RaRecord,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField
} from "react-admin";
import Utils from "utils";
import { cursorDefaultStyles } from "utils/RowClickFalseStyles";
import { useContext } from "react";
import { AdminIdentityContext } from "App";
import {
    INVITATION_PENDING_OPTION,
    invitationStatusOptions
} from "constants/invitationStatusOptions";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";

const companyInvitationsFilter = [
    <SearchInput source="email" alwaysOn />,
    <SelectInput source="status" choices={invitationStatusOptions} alwaysOn />,
    <ReferenceInput
        source="inviterId"
        reference="investors"
        sort={{ order: "ASC", field: "" }}
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={(inviter) => ({ contactName: inviter })}
            optionText={(inviter) => {
                const { firstName, lastName } = inviter.contact;
                return `${firstName} ${lastName}`;
            }}
        />
    </ReferenceInput>
];

export const CompanyInvitationsList = (props: any) => {
    const isSuperAdmin = useContext(AdminIdentityContext);

    return (
        <List
            {...props}
            sort={{ field: undefined }}
            perPage={50}
            actions={<ListActionsToolbarWithoutFilters />}
            filters={companyInvitationsFilter}
        >
            <Datagrid
                rowClick="false"
                sx={cursorDefaultStyles}
                bulkActionButtons={
                    <BulkDeleteButtons isSuperAdmin={isSuperAdmin} />
                }
            >
                <EmailField source="email" />
                <TextField source="status" sortable={false} />
                <ReferenceField
                    label="Created by"
                    source="inviterId"
                    reference="investors"
                    sortable={false}
                >
                    <ShowAboveClickableRow>
                        <FunctionField
                            render={(record: any) => (
                                <InvestorNameField data={record} />
                            )}
                        />
                    </ShowAboveClickableRow>
                </ReferenceField>
                <DateField
                    source="createdAt"
                    options={Utils.DateFieldOptions.DAY_MONTH_YEAR}
                />
                <ReferenceField
                    label="Company"
                    source="inviteeId"
                    reference="companies"
                    sortable={false}
                >
                    <ShowAboveClickableRow>
                        <FunctionField
                            render={(record: any) => (
                                <CompanyNameField data={record} />
                            )}
                        />
                    </ShowAboveClickableRow>
                </ReferenceField>
                <DateField
                    source="usedAt"
                    options={Utils.DateFieldOptions.DAY_MONTH_YEAR}
                />
                <FunctionField
                    source="url"
                    render={(record: RaRecord<Identifier>) => (
                        <CopyToClipboardButton
                            textToCopy={record.url}
                            successMessage="URL is copied!"
                            isDisabled={Boolean(
                                record.status !== INVITATION_PENDING_OPTION.id
                            )}
                        />
                    )}
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};
