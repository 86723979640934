import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";
import Typography from "@mui/material/Typography";
import { PageTitle } from "components/navbar/PageTitle";
import { DefaultTextInput } from "components/default-text-input";
import { EditToolbar } from "components/toolbar/EditToolbar";

export const AdminEdit = () => (
    <Edit
        title={
            <PageTitle generatePageTitle={(record: any) => `${record?.name}`} />
        }
    >
        <SimpleForm toolbar={<EditToolbar isSuperAdmin={true} />}>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Profile
            </Typography>

            <DefaultTextInput source="email" readOnly />
            <DefaultTextInput source="name" />

            <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Super Admin
            </Typography>

            <BooleanInput source="isSuper" label="Grant permissions" />
        </SimpleForm>
    </Edit>
);
