import API from "api";
import { useEffect, useState } from "react";

export type NormalizedCountriesData = { id: string; name: string }[];

export const useCountries = () => {
    const [countries, setCountries] = useState<NormalizedCountriesData>([]);

    useEffect(() => {
        const getCountries = async () => {
            const { data } = await API.Backend.Resource.getList(
                "countries",
                {}
            );
            const normalizedCountriesData = data.map((country: string) => ({
                id: country,
                name: country
            }));
            setCountries(normalizedCountriesData);
        };
        getCountries();
    }, []);

    return { countries };
};
