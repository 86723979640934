import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const PaperFormWrapper = styled(Paper)<PaperProps>(() => ({
    display: "flex",
    flexDirection: "column",
    padding: "16px 16px 24px 16px",
    marginTop: "6em",
    width: "300px",
    fontFamily: "Gabarito, Arial, sans-serif"
}));
