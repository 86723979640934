import { useGetOne, useRecordContext } from "react-admin";
import PARSERS from "utils/parsers";
import { ReferenceFunctionFieldProps } from "./types";

export const ReferenceFunctionField = ({
    source,
    idPath,
    render: DisplayedComponent
}: ReferenceFunctionFieldProps) => {
    const record = useRecordContext();
    let id = PARSERS.getFieldValue(idPath, record);
    if (!id) return null;

    const { data, isFetched } = useGetOne(source, { id });
    if (!isFetched) return null;

    return <DisplayedComponent data={data} />;
};
