import {
    BooleanInput,
    FunctionField,
    ReferenceField,
    useRecordContext
} from "react-admin";
import Typography from "@mui/material/Typography";
import { DefaultTextInput } from "components/default-text-input";

export const ParentQuestionSection = () => {
    const record = useRecordContext();
    const { parentId } = record;

    return (
        parentId && (
            <>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Parent
                </Typography>

                <ReferenceField
                    source="parentId"
                    reference="questions"
                    sx={{ marginBottom: 1.5 }}
                    label="Parent"
                >
                    <FunctionField
                        source="questions"
                        label="Parent"
                        render={(record: any) => {
                            return <span>{record.text}</span>;
                        }}
                    />
                </ReferenceField>

                <DefaultTextInput source="conditions" readOnly />

                <BooleanInput source="isGrouped" readOnly label="Grouped" />
            </>
        )
    );
};
