import { DataProvider } from "react-admin";

import API from "api";

const dataProvider: DataProvider = {
    getList: async (resource, params) => {
        let {
            filter,
            pagination: { page, perPage },
            sort: { field, order }
        } = params;
        page -= 1;
        const { ok, data, pagination, error } =
            await API.Backend.Resource.getList(resource, {
                filter,
                order: field
                    ? {
                          [field]: order
                      }
                    : {},
                page: page.toString(),
                perPage: perPage.toString()
            });

        if (!ok) {
            throw new Error(error);
        }

        return {
            data,
            total: pagination.totalRecords,
            pageInfo: {
                hasNextPage: pagination.nextPage !== null,
                hasPrevPage: pagination.prevPage !== null
            }
        };
    },
    getOne: async (resource, params) => {
        const { ok, data, error } = await API.Backend.Resource.getOne(
            resource,
            params.id.toString()
        );

        if (!ok) {
            throw new Error(error);
        }

        return {
            data: data
        };
    },
    getMany: async (resource, params) => {
        const { ok, data, error } = await API.Backend.Resource.getMany(
            resource,
            params.ids.map((id) => id.toString())
        );
        if (!ok) {
            throw new Error(error);
        }

        return {
            data
        };
    },
    getManyReference: async (resource, params) => {
        const { ok, data, error } = await API.Backend.Resource.getManyReference(
            resource,
            params.target,
            params.id.toString(),
            {
                filter: params.filter,
                order: params.sort.field
                    ? {
                          [params.sort.field]: params.sort.order
                      }
                    : {}
            }
        );

        if (!ok) {
            throw new Error(error);
        }

        return {
            data,
            total: data.length,
            pageInfo: {
                hasNextPage: false,
                hasPrevPage: false
            }
        };
    },
    create: async (resource, params) => {
        const { ok, data, error } = await API.Backend.Resource.create(
            resource,
            params.data
        );

        if (!ok) {
            throw new Error(error);
        }

        return {
            data
        };
    },
    update: async (resource, params) => {
        const { id, ...paramsData } = params.data;
        const { ok, data, error } = await API.Backend.Resource.update(
            resource,
            params.id,
            paramsData
        );

        if (!ok) {
            throw new Error(error);
        }

        return {
            data
        };
    },
    updateMany: async (resource, params) => {
        const { ok, data, errors } = await API.Backend.Resource.updateMany(
            resource,
            params.ids.map((id) => id.toString()),
            params.data
        );

        if (!ok) {
            throw new Error(errors.join("\n"));
        }

        return {
            data
        };
    },
    delete: async (resource, params) => {
        const { ok, data, error } = await API.Backend.Resource.remove(
            resource,
            params.id.toString()
        );

        if (!ok) {
            throw new Error(error);
        }

        return {
            data
        };
    },
    deleteMany: async (resource, params) => {
        const { ok, data, errors } = await API.Backend.Resource.removeMany(
            resource,
            params.ids.map((id) => id.toString())
        );

        if (!ok) {
            throw new Error(errors.join("\n"));
        }

        return {
            data
        };
    }
};

export default dataProvider;
