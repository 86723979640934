export const businessOptions = [
    {
        id: "B2B",
        name: "B2B"
    },
    {
        id: "B2C",
        name: "B2C"
    },
    {
        id: "B2B2C",
        name: "B2B2C"
    },
    {
        id: "DTC",
        name: "DTC"
    },
    {
        id: "Other",
        name: "Other"
    }
];
