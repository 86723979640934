import { AuthProvider } from "react-admin";

import API from "api";

const authProvider: AuthProvider = {
    getPermissions: async () => {
        return;
    },
    checkAuth: async () => {
        const value = await API.Backend.Me.getMe();

        if (!value.data.auth) {
            throw new Error();
        }
    },
    login: async ({ username, password }) => {
        const { ok, error } = await API.Backend.Auth.signIn({
            email: username,
            password
        });

        if (!ok) {
            throw new Error(error);
        }

        API.LocalStorage.set("is-logged-in", "true");
    },
    logout: async () => {
        const response: any = await API.Backend.Auth.signOut();

        if (!response.ok) {
            throw new Error(response.error);
        }

        API.LocalStorage.del("is-logged-in");
    },
    checkError: async (error) => {
        if (!error) {
            return;
        }
        const { status } = error;

        if (status === 401 || status === 403) {
            API.LocalStorage.del("is-logged-in");
        }
    }
};

export default authProvider;
