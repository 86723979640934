export const INVITATION_PENDING_OPTION = { id: "pending", name: "pending" };
export const INVITATION_ACCEPTED_OPTION = { id: "accepted", name: "accepted" };
export const INVITATION_DELETED_OPTION = { id: "deleted", name: "deleted" };
export const INVITATION_ONBOARDING_OPTION = {
    id: "onboarding",
    name: "onboarding"
};

export const invitationStatusOptions = [
    INVITATION_PENDING_OPTION,
    INVITATION_ACCEPTED_OPTION,
    INVITATION_DELETED_OPTION,
    INVITATION_ONBOARDING_OPTION
];
