const DAY_MONTH_YEAR = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
} as const;

const DATE_FIELD_OPTIONS = {
    DAY_MONTH_YEAR
};

export default DATE_FIELD_OPTIONS;
