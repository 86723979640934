import { FetchHttpFactory } from "../../http";
import { InvitationResponseDTO } from "./invitation.dto";

const fetchHttpFactory = new FetchHttpFactory();
const http = fetchHttpFactory.createHttp("/admin");

export const checkInvitation = (token: string) => {
    const requestUrl = `/auth/${token}`;
    return http.get<InvitationResponseDTO>(requestUrl);
};
