const queryParams = (data: any) =>
    Object.keys(data).reduce(
        (acc, key) => {
            acc[key] = data[key].toString();
            return acc;
        },
        {} as Record<string, string>
    );

const getFieldValue = (fieldPath: string, record: Record<string, any>) => {
    const [field, ...rest] = fieldPath.split(".");
    const fieldValue = record[field];
    let returnFieldValue = fieldValue;
    if (rest.length)
        returnFieldValue = getFieldValue(rest.join("."), fieldValue);
    return returnFieldValue;
};

const PARSERS = {
    queryParams,
    getFieldValue
};

export default PARSERS;
