export const industryOptions = [
    {
        id: "AdTech",
        name: "Adtech"
    },
    {
        id: "AdvancedManufacturing",
        name: "Advanced manufacturing"
    },
    {
        id: "AgTech",
        name: "Agtech"
    },
    {
        id: "ArtificialIntelligenceAndMachineLearning",
        name: "Artificial intelligence and machine learning (AI/ML)"
    },
    {
        id: "AudioTech",
        name: "Audiotech"
    },
    {
        id: "AugmentedReality",
        name: "Augmented reality (AR)"
    },
    {
        id: "AutonomousCars",
        name: "Autonomous cars"
    },
    {
        id: "B2BPayments",
        name: "B2B payments"
    },
    {
        id: "Beauty",
        name: "Beauty"
    },
    {
        id: "BigData",
        name: "Big Data"
    },
    {
        id: "Cannabis",
        name: "Cannabis"
    },
    {
        id: "CarSharing",
        name: "Carsharing"
    },
    {
        id: "CleanTech",
        name: "Cleantech"
    },
    {
        id: "ClimateTech",
        name: "Climate tech"
    },
    {
        id: "CloudTechAndDevOps",
        name: "Cloudtech and DevOps"
    },
    {
        id: "ConstructionTechnology",
        name: "Construction technology"
    },
    {
        id: "CryptocurrencyAndBlockchain",
        name: "Cryptocurrency and blockchain"
    },
    {
        id: "Cybersecurity",
        name: "Cybersecurity"
    },
    {
        id: "Dating",
        name: "Dating"
    },
    {
        id: "DigitalHealth",
        name: "Digital health"
    },
    {
        id: "Ecommerce",
        name: "Ecommerce"
    },
    {
        id: "EdTech",
        name: "Edtech"
    },
    {
        id: "EphemeralContent",
        name: "Ephemeral content"
    },
    {
        id: "ESports",
        name: "eSports"
    },
    {
        id: "FashionTech",
        name: "Fashion tech"
    },
    {
        id: "FemTech",
        name: "Femtech"
    },
    {
        id: "FinTech",
        name: "Fintech"
    },
    {
        id: "FoodTech",
        name: "Foodtech"
    },
    {
        id: "FutureOfWork",
        name: "Future of Work"
    },
    {
        id: "Gaming",
        name: "Gaming"
    },
    {
        id: "HealthTech",
        name: "Healthtech"
    },
    {
        id: "HRtech",
        name: "HRtech"
    },
    {
        id: "ImpactInvesting",
        name: "Impact investing"
    },
    {
        id: "Industrials",
        name: "Industrials"
    },
    {
        id: "Infrastructure",
        name: "Infrastructure"
    },
    {
        id: "InsurTech",
        name: "Insurtech"
    },
    {
        id: "InternetOfThings",
        name: "Internet of Things (IoT)"
    },
    {
        id: "LegalTech",
        name: "Legal tech"
    },
    {
        id: "LifeSciences",
        name: "Life sciences"
    },
    {
        id: "LOHASAndWellness",
        name: "Lifestyles of Health and Sustainability (LOHAS) and wellness"
    },
    {
        id: "Manufacturing",
        name: "Manufacturing"
    },
    {
        id: "Media",
        name: "Media"
    },
    {
        id: "MarketingTech",
        name: "Marketing tech"
    },
    {
        id: "MicroMobility",
        name: "Micro-mobility"
    },
    {
        id: "Mobile",
        name: "Mobile"
    },
    {
        id: "MobileCommerce",
        name: "Mobile commerce"
    },
    {
        id: "MobilityTech",
        name: "Mobility tech"
    },
    {
        id: "MortgageTech",
        name: "Mortgage tech"
    },
    {
        id: "Music",
        name: "Music"
    },
    {
        id: "Nanotechnology",
        name: "Nanotechnology"
    },
    {
        id: "OilAndGas",
        name: "Oil and gas"
    },
    {
        id: "PetTech",
        name: "Pet tech"
    },
    {
        id: "RealEstateTech",
        name: "Real estate tech"
    },
    {
        id: "RestaurantTech",
        name: "Restaurant tech"
    },
    {
        id: "RideSharing",
        name: "Ridesharing"
    },
    {
        id: "RoboticsAndDrones",
        name: "Robotics and drones"
    },
    {
        id: "Security",
        name: "Security"
    },
    {
        id: "SocialMedia",
        name: "Social media"
    },
    {
        id: "SoftwareAsAService",
        name: "Software as a service (SaaS)"
    },
    {
        id: "SpaceTech",
        name: "Space tech"
    },
    {
        id: "SupplyChainTechnology",
        name: "Supply chain technology"
    },
    {
        id: "TMT",
        name: "Technology, media and telecommunications (TMT)"
    },
    {
        id: "Travel",
        name: "Travel"
    },
    {
        id: "VR",
        name: "Virtual reality (VR)"
    },
    {
        id: "WearablesAndQuantifiedSelf",
        name: "Wearables and quantified self"
    },
    {
        id: "Web3",
        name: "Web 3"
    },
    {
        id: "ThreeDPrinting",
        name: "3D printing"
    }
];
