import { PropsWithChildren } from "react";

export const ShowAboveClickableRow = ({ children }: PropsWithChildren) => {
    return (
        <span
            style={{
                zIndex: 2,
                position: "relative"
            }}
        >
            {children}
        </span>
    );
};
