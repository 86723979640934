import { Edit, FunctionField, SelectInput, SimpleForm } from "react-admin";
import Typography from "@mui/material/Typography";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { useContext } from "react";
import { AdminIdentityContext } from "App";
import { DefaultTextInput } from "components/default-text-input";
import { investorContactTypes } from "constants/contractTypes";
import { UserPictureField } from "components/user-picture-field/UserPictureField";

export const InvestorEdit = () => {
    const isSuperAdmin = useContext(AdminIdentityContext);

    const handleTransform = (data: any) => {
        const {
            contact: { picture, ...contactRest }, // To avoid picture being sent to the backend
            ...rest
        } = data;

        return {
            contact: contactRest,
            ...rest
        };
    };

    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) =>
                        `${record?.contact?.firstName} ${record?.contact?.lastName}`
                    }
                />
            }
            transform={handleTransform}
        >
            <SimpleForm toolbar={<EditToolbar isSuperAdmin={isSuperAdmin} />}>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Profile
                </Typography>

                <DefaultTextInput source="email" />
                <DefaultTextInput
                    source="contact.firstName"
                    label="First name"
                />
                <DefaultTextInput source="contact.lastName" label="Last name" />
                <DefaultTextInput source="contact.role" label="Role" />

                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Billing
                </Typography>

                <DefaultTextInput source="companyName" />
                <DefaultTextInput source="vat" label="VAT number" />
                <DefaultTextInput source="address.street" label="Street" />
                <DefaultTextInput source="address.city" label="City" />
                <DefaultTextInput source="address.postcode" label="Postcode" />
                <DefaultTextInput source="address.state" label="State" />
                <DefaultTextInput source="address.country" label="Country" />

                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Contract
                </Typography>

                <SelectInput
                    source="contract.type"
                    choices={investorContactTypes}
                />

                <DefaultTextInput source="contract.details" multiline />

                <FunctionField
                    render={(record: any) => (
                        <UserPictureField src={record?.contact?.picture?.url} />
                    )}
                />
            </SimpleForm>
        </Edit>
    );
};
