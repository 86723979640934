type ImageDropdownInputProps = {
    src: string | null;
    style?: React.CSSProperties;
};

export const UserPictureField = ({ style, src }: ImageDropdownInputProps) => {
    return (
        <div
            style={{
                background: "white",
                width: "400px",
                height: "140px",
                border: "1px solid grey",
                marginBottom: "20px",
                display: "flex",
                ...style
            }}
        >
            {src ? (
                <img
                    src={src}
                    style={{
                        width: "140px",
                        height: "140px",
                        objectFit: "cover",
                        margin: "0px auto"
                    }}
                />
            ) : (
                <span style={{ color: "grey", margin: "auto" }}>No image</span>
            )}
        </div>
    );
};
