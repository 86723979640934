import { BooleanInput, Edit, ReferenceField, SimpleForm } from "react-admin";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { DefaultTextInput } from "components/default-text-input";
import { ParentQuestionSection } from "components/question/ParentQuestionSection";

export const QuestionEdit = () => {
    const handleTransform = (data: any) => {
        const { text, id } = data;
        return { id, text };
    };

    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) => `${record?.key}`}
                />
            }
            transform={handleTransform}
        >
            <SimpleForm toolbar={<EditToolbar isSuperAdmin={false} />}>
                <DefaultTextInput source="text" multiline />
                <DefaultTextInput source="key" readOnly />

                <ReferenceField
                    source="surveyId"
                    reference="surveys"
                    sx={{ width: "100%" }}
                >
                    <DefaultTextInput source="name" readOnly label="Survey" />
                </ReferenceField>

                <DefaultTextInput source="order" readOnly />

                <DefaultTextInput source="pattern.type" readOnly label="Type" />

                <BooleanInput source="isRequired" readOnly label="Required" />

                <ParentQuestionSection />
            </SimpleForm>
        </Edit>
    );
};
