import {
    BooleanField,
    Datagrid,
    DateInput,
    Edit,
    FunctionField,
    ImageField,
    ImageInput,
    maxLength,
    ReferenceArrayField,
    ReferenceField,
    SelectInput,
    SimpleForm,
    TextField
} from "react-admin";
import Typography from "@mui/material/Typography";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { useContext } from "react";
import { AdminIdentityContext } from "App";
import { industryOptions } from "constants/industryOptions";
import { businessOptions } from "constants/businessOptions";
import { cursorDefaultStyles } from "utils/RowClickFalseStyles";
import { MemberNameField } from "components/member/MemberNameField";
import { ShowAboveClickableRow } from "components/show-above-clickable-row";
import { DefaultTextInput } from "components/default-text-input";
import { UserPictureField } from "components/user-picture-field/UserPictureField";

export const CompanyEdit = () => {
    const isSuperAdmin = useContext(AdminIdentityContext);
    const handleTransform = (data: any) => {
        const { members, investors, logo, ...rest } = data;

        return { ...rest };
    };

    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) => `${record?.name}`}
                />
            }
            transform={handleTransform}
        >
            <SimpleForm toolbar={<EditToolbar isSuperAdmin={isSuperAdmin} />}>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Details
                </Typography>

                <DefaultTextInput source="name" label="Company name" />

                <DefaultTextInput source="website" />

                <SelectInput
                    source="industry"
                    choices={industryOptions}
                    optionValue="name"
                />

                <SelectInput
                    source="businessType"
                    choices={businessOptions}
                    optionValue="name"
                />

                <DateInput
                    source="foundingDate"
                    label="Date of incorporation"
                />

                <DefaultTextInput
                    source="country"
                    label="Country of incorporation"
                />

                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Description
                </Typography>

                <DefaultTextInput
                    source="descriptionShort"
                    multiline
                    label="1 sentence description"
                    validate={maxLength(300)}
                />

                <DefaultTextInput
                    source="descriptionLong"
                    multiline
                    label="Long description"
                    validate={maxLength(1000)}
                />

                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Members
                </Typography>

                <ReferenceArrayField
                    reference="members"
                    source="members"
                    sort={{ field: "", order: "DESC" }}
                    sx={{ width: "50%", minWidth: 600 }}
                >
                    <Datagrid
                        rowClick="false"
                        sx={cursorDefaultStyles}
                        bulkActionButtons={false}
                    >
                        <ReferenceField
                            label="Name"
                            source="id"
                            reference="members"
                        >
                            <ShowAboveClickableRow>
                                <FunctionField
                                    render={(record: any) => (
                                        <MemberNameField data={record} />
                                    )}
                                />
                            </ShowAboveClickableRow>
                        </ReferenceField>

                        <TextField source="role" />

                        <BooleanField source="isFounder" />

                        <BooleanField source="isSuper" />
                    </Datagrid>
                </ReferenceArrayField>

                <FunctionField
                    render={(record: any) => (
                        <UserPictureField
                            src={record?.logo?.url}
                            style={{ marginTop: "30px" }}
                        />
                    )}
                />
            </SimpleForm>
        </Edit>
    );
};
