let API_ROOT_URL = "http://localhost:8080";

try {
    API_ROOT_URL = process.env.REACT_APP_API_BASE_URL ?? API_ROOT_URL;
} catch (error: unknown) {
    console.error("Some environment variables are missing");
}

const ADMIN_BASE_URL = `${API_ROOT_URL}/admin`;

export { API_ROOT_URL, ADMIN_BASE_URL };
