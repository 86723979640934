import { BooleanInput, Create, SimpleForm, required } from "react-admin";
import Typography from "@mui/material/Typography";
import { DefaultTextInput } from "components/default-text-input";

export const AdminInvitationCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Send admin invitation
                </Typography>

                <DefaultTextInput
                    source="email"
                    type="email"
                    validate={[required()]}
                />

                <BooleanInput source="isSuper" label="Super Admin" />
            </SimpleForm>
        </Create>
    );
};
