import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    DateInput,
    Edit,
    FunctionField,
    NumberInput,
    ReferenceField,
    SimpleForm
} from "react-admin";
import Typography from "@mui/material/Typography";
import { CompanyNameField } from "components/company/CompanyNameField";
import { MemberMotivationSection } from "components/member/MemberMotivationSection";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { useContext } from "react";
import { AdminIdentityContext } from "App";
import { useCountries } from "hooks/useCountries";
import { DefaultTextInput } from "components/default-text-input";
import { UserPictureField } from "components/user-picture-field/UserPictureField";
import { RoleSection } from "components/role-section/RoleSection";
import { LocationSection } from "components/location-section/LocationSection";

export const MemberEdit = () => {
    const isSuperAdmin = useContext(AdminIdentityContext);
    const { countries } = useCountries();

    const handleTransform = (data: any) => {
        const {
            isSuper,
            company,
            isFounder,
            founderWhy,
            startedCompanyWhy,
            picture, // To avoid picture being sent to the backend
            roleDescription,
            ...rest
        } = data;
        const motivationData = { founderWhy, startedCompanyWhy };
        const clearedMotivationData = {
            founderWhy: null,
            startedCompanyWhy: null
        };

        const isCustomRole = rest.role === "Other";
        return {
            isFounder,
            ...rest,
            roleDescription: isCustomRole ? roleDescription : null,
            ...(isFounder ? motivationData : clearedMotivationData)
        };
    };

    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) =>
                        `${record?.firstName} ${record?.lastName}`
                    }
                />
            }
            transform={handleTransform}
        >
            <SimpleForm toolbar={<EditToolbar isSuperAdmin={isSuperAdmin} />}>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Profile
                </Typography>
                <BooleanInput source="isSuper" readOnly label="Super member" />
                <DefaultTextInput source="email" type="email" />
                <DefaultTextInput source="firstName" />
                <DefaultTextInput source="lastName" />
                <DefaultTextInput source="linkedin" />
                <FunctionField
                    sx={{ width: "100%" }}
                    render={(record: any) => {
                        if (record.gender === "Prefer to self-describe")
                            return (
                                <DefaultTextInput
                                    sx={{ margin: "0px" }}
                                    source="genderDescription"
                                    multiline
                                    readOnly
                                />
                            );
                        else
                            return (
                                <DefaultTextInput
                                    source="gender"
                                    readOnly
                                    sx={{ margin: "0px" }}
                                />
                            );
                    }}
                />
                <DefaultTextInput source="year" label="Year of birth" />
                <AutocompleteArrayInput
                    sx={{
                        "& .MuiFormControl-root": { margin: "0px" },
                        minWidth: "400px"
                    }}
                    source="nationality"
                    optionValue="id"
                    optionText="name"
                    choices={countries}
                />

                <LocationSection
                    citySource="location.city"
                    countrySource="location.country"
                />

                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Company
                </Typography>
                <ReferenceField
                    source="company"
                    reference="companies"
                    sx={{ marginBottom: 1.5 }}
                >
                    <FunctionField
                        source="companies"
                        render={(record: any) => {
                            return <CompanyNameField data={record} />;
                        }}
                    />
                </ReferenceField>
                <DateInput source="joined" />
                <RoleSection />
                <NumberInput source="sharesAmount" label="Shares" min={0} />
                <BooleanInput source="isFounder" label="Co-founder" />
                <MemberMotivationSection />
                <FunctionField
                    render={(record: any) => (
                        <UserPictureField src={record?.picture?.url} />
                    )}
                />
            </SimpleForm>
        </Edit>
    );
};
