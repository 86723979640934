import { deepmerge } from "@mui/utils";
import { radiantLightTheme } from "react-admin";

export const customRadiantLightTheme = deepmerge(radiantLightTheme, {
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&.RaMenuItemLink-active .MuiListItemIcon-root": {
                        color: "white"
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    "& .MuiAutocomplete-inputRoot": {
                        minHeight: "40px",
                        paddingTop: "0px !important",
                        paddingBottom: "0px !important"
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    height: "40px",
                    boxSizing: "border-box !important"
                }
            }
        }
    }
});
