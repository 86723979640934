import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const GradientPageWrapper = styled(Box)<BoxProps>(() => ({
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage:
        "radial-gradient(circle at 50% 14em, rgb(49, 50, 100) 0%, rgb(0, 2, 59) 60%, rgb(0, 2, 59) 100%)"
}));
