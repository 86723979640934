import API from "api";
import { useEffect, useState } from "react";
export type NormalizedCountriesData = { id: string; name: string }[];

export const useCitySearch = (selectedCountry: string, city: string) => {
    const [filteredCitiesList, setFilteredCitiesList] =
        useState<NormalizedCountriesData>([]);

    useEffect(() => {
        if (!selectedCountry) return;

        const getCities = async () => {
            try {
                const { data } = await API.Backend.Resource.getList(
                    "locations",
                    {
                        search: city,
                        perPage: "20",
                        page: "0",
                        filter: {
                            country: selectedCountry
                        }
                    }
                );
                const normalizedCitiesData = data?.map(
                    ({ id, city }: { id: string; city: string }) => ({
                        id: id,
                        name: city
                    })
                );
                setFilteredCitiesList(normalizedCitiesData);
            } catch (error) {
                console.log(error);
            }
        };
        getCities();
    }, [selectedCountry, city]);

    return { filteredCitiesList };
};
