import Tooltip from "@mui/material/Tooltip";

type TruncatedTextFieldProps = {
    text: string | undefined;
    style?: React.CSSProperties;
};

export const TruncatedTextField = ({
    style,
    text
}: TruncatedTextFieldProps) => {
    if (!text) return null;
    return (
        <Tooltip title={text}>
            <div
                style={{
                    textOverflow: "ellipsis",
                    textWrap: "nowrap",
                    width: "clamp(100px,17vw,300px)",
                    display: "block",
                    overflow: "hidden",
                    ...style
                }}
            >
                <span>{text}</span>
            </div>
        </Tooltip>
    );
};
