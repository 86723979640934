import { Login as BaseLogin, LoginForm } from "react-admin";
import Link from "@mui/material/Link";

const Login = () => {
    return (
        <BaseLogin
            children={
                <div>
                    <LoginForm></LoginForm>
                    <Link
                        href="/forgot-password"
                        style={{
                            display: "block",
                            textAlign: "center",
                            marginBottom: "1rem",
                            marginTop: "-0.6rem"
                        }}
                    >
                        Forgot Password
                    </Link>
                </div>
            }
        />
    );
};

export default Login;
