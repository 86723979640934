import { ADMIN_BASE_URL, API_ROOT_URL } from "env";
import QueryString from "qs";

const headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json"
};

type CustomRequestInit = RequestInit;

abstract class HttpFactory {
    abstract createHttp(url: string): Http;
}

abstract class Http {
    abstract builtUrl: string;

    abstract get<T>(
        url: string,
        query?: Record<string, any>,
        config?: CustomRequestInit
    ): Promise<T>;
    abstract delete<T>(url: string, config?: CustomRequestInit): Promise<T>;
    abstract put<T>(
        url: string,
        data?: any,
        config?: CustomRequestInit
    ): Promise<T>;
    abstract post<T>(
        url: string,
        data?: any,
        config?: CustomRequestInit
    ): Promise<T>;
    abstract patch<T>(
        url: string,
        data?: any,
        config?: CustomRequestInit
    ): Promise<T>;

    protected buildPath(path: string, trailingPath: string) {
        return `${path}${trailingPath}`;
    }
}

class FetchHttpFactory extends HttpFactory {
    createHttp(url?: string): Http {
        return new FetchHttp(url);
    }
}

class FetchHttp extends Http {
    builtUrl: string;
    headers = headers;
    baseUrl = API_ROOT_URL;

    constructor(mainUrl?: string) {
        super();
        this.builtUrl = `${this.baseUrl}${mainUrl}`;
    }

    private handleResponse = <T>(response: Response): Promise<T> =>
        response.json();

    private buildQuery = (queryObj: any) =>
        QueryString.stringify(queryObj, { arrayFormat: "brackets" });

    async get<T>(
        url: string,
        query: Record<string, any> = {},
        config: CustomRequestInit = {}
    ): Promise<T> {
        const urlObj = new URL(`${this.builtUrl}${url}`);
        const baseQuery = urlObj.search;
        const queryString = this.buildQuery(query);
        
        urlObj.search = baseQuery
            ? `${baseQuery}&${queryString}`
            : queryString
              ? `?${this.buildQuery(query)}`
              : "";

        const response = await fetch(urlObj.toString(), {
            method: "GET",
            credentials: "include",
            headers: this.headers,
            ...config
        });

        return this.handleResponse<T>(response);
    }

    async post<T>(
        url: string,
        data?: any,
        config?: CustomRequestInit
    ): Promise<T> {
        const response = await fetch(`${this.builtUrl}${url}`, {
            method: "POST",
            headers: this.headers,
            credentials: "include",
            body: JSON.stringify(data),
            ...config
        });

        return this.handleResponse<T>(response);
    }

    async put<T>(
        url: string,
        data?: any,
        config?: CustomRequestInit
    ): Promise<T> {
        const response = await fetch(`${this.builtUrl}${url}`, {
            method: "PUT",
            headers: this.headers,
            credentials: "include",
            body: JSON.stringify(data),
            ...config
        });

        return this.handleResponse<T>(response);
    }

    async patch<T>(
        url: string,
        data?: any,
        config?: CustomRequestInit
    ): Promise<T> {
        const response = await fetch(`${this.builtUrl}${url}`, {
            method: "PATCH",
            headers: this.headers,
            credentials: "include",
            body: JSON.stringify(data),
            ...config
        });

        return this.handleResponse<T>(response);
    }

    async delete<T>(url: string, config?: CustomRequestInit): Promise<T> {
        const response = await fetch(`${this.builtUrl}${url}`, {
            method: "DELETE",
            credentials: "include",
            headers: this.headers,
            ...config
        });

        return this.handleResponse<T>(response);
    }
}

export { HttpFactory, FetchHttpFactory };
