import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { useCopyToClipboardButton } from "./useCopyToClipboardButton";
import { CopyToClipboardButtonProps } from "./types";

export const CopyToClipboardButton = ({
    textToCopy,
    successMessage,
    isDisabled
}: CopyToClipboardButtonProps) => {
    const { handleCopyTextToClipboard, handleSnackbarClose, isSnackbarOpened } =
        useCopyToClipboardButton();

    return (
        <>
            <Button
                size="small"
                sx={{ lineHeight: "19px" }}
                variant="outlined"
                onClick={(e) => {
                    e.stopPropagation();
                    handleCopyTextToClipboard(textToCopy);
                }}
                disabled={isDisabled || !Boolean(textToCopy?.length)}
            >
                Copy
            </Button>

            <Snackbar
                open={isSnackbarOpened}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                ContentProps={{ sx: { minWidth: "100px !important" } }}
                message={successMessage}
            />
        </>
    );
};
