import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import API from "api/backend";
import { InvitationTokenPayload, InvitationType } from "types/invitation";
import { invitationFormSchema } from "schemas/invitationFormSchema";

type FormInputs = {
    name: string;
    password: string;
    repeatPassword: string;
};

export const useInvitation = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        getFieldState,
        trigger,
        watch,
        formState: { errors }
    } = useForm<FormInputs>({
        mode: "onChange",
        resolver: joiResolver(invitationFormSchema)
    });

    const [isLoading, setIsLoading] = useState(false);
    const [payload, setPayload] = useState<InvitationTokenPayload>();
    const [isSomethingWrong, setIsSomethingWrong] = useState(false);

    const passwordState = watch("password");

    const handleOnSnackbarClose = () => {
        setIsSomethingWrong(false);
    };

    useEffect(() => {
        const isRepeatPasswordDirty = getFieldState("repeatPassword").isDirty;
        if (isRepeatPasswordDirty) {
            trigger("repeatPassword");
        }
    }, [passwordState]);

    const onSubmit = async ({ name, password }: FormInputs) => {
        await API.Auth.signUp({ password, token: token!, name });
        const { ok } = await API.Auth.signIn({
            email: payload!.email,
            password
        });
        if (ok) navigate("/", { replace: true });
        else {
            setIsSomethingWrong(true);
        }
    };

    useEffect(() => {
        (async () => {
            if (token) {
                setIsLoading(true);
                try {
                    const { ok, data } =
                        await API.Invitation.checkInvitation(token);

                    if (ok && data.type === InvitationType.Admin)
                        setPayload(data);
                    else navigate("/", { replace: true });
                } catch (err: any) {
                    console.log(err);
                    navigate("/", { replace: true });
                } finally {
                    setIsLoading(false);
                }
            } else navigate("/", { replace: true });
        })();
    }, []);

    return {
        isSomethingWrong,
        handleOnSnackbarClose,
        isLoading,
        payload,
        handleSubmit: handleSubmit(onSubmit),
        errors,
        register
    };
};
