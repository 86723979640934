import { Edit, SimpleForm } from "react-admin";
import { PageTitle } from "components/navbar/PageTitle";
import { DefaultTextInput } from "components/default-text-input";
import { EditToolbar } from "components/toolbar/EditToolbar";

export const ConclusionEdit = () => {
    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) => `${record?.id}`}
                />
            }
        >
            <SimpleForm toolbar={<EditToolbar isSuperAdmin={false} />}>
                <DefaultTextInput source="id" readOnly label="key" />

                <DefaultTextInput source="lowTitle" />
                <DefaultTextInput source="lowText" multiline maxRows={10} />

                <DefaultTextInput source="mediumTitle" />
                <DefaultTextInput source="mediumText" multiline maxRows={10} />

                <DefaultTextInput source="highTitle" />
                <DefaultTextInput source="highText" multiline maxRows={10} />
            </SimpleForm>
        </Edit>
    );
};
