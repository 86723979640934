import { AdminIdentityContext } from "App";
import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import { businessOptions } from "constants/businessOptions";
import { industryOptions } from "constants/industryOptions";
import { NormalizedCountriesData, useCountries } from "hooks/useCountries";
import { useContext } from "react";
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    FunctionField,
    List,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField,
    UrlField
} from "react-admin";
import Utils from "utils";

const getPostFilters = (countries: NormalizedCountriesData) => {
    return [
        <SearchInput source="name" alwaysOn />,

        <AutocompleteInput
            source="industry"
            choices={industryOptions}
            optionValue="name"
            alwaysOn
        />,

        <SelectInput
            source="businessType"
            choices={businessOptions}
            optionValue="name"
            alwaysOn
        />,

        <AutocompleteInput
            source="country"
            optionValue="id"
            optionText="name"
            choices={countries}
            alwaysOn
        />
    ];
};

export const CompanyList = (props: any) => {
    const isSuperAdmin = useContext(AdminIdentityContext);
    const { countries } = useCountries();
    return (
        <List
            {...props}
            sort={{ field: undefined }}
            perPage={50}
            actions={<ListActionsToolbarWithoutFilters />}
            filters={getPostFilters(countries)}
        >
            <Datagrid
                rowClick="edit"
                bulkActionButtons={
                    <BulkDeleteButtons isSuperAdmin={isSuperAdmin} />
                }
            >
                <TextField source="name" label="Name" />
                <UrlField source="website" sortable={false} />
                <TextField source="industry" label="Industry" />
                <DateField
                    source="foundingDate"
                    options={Utils.DateFieldOptions.DAY_MONTH_YEAR}
                    sortable={false}
                />
                <TextField source="businessType" label="Business type" />
                <TextField source="country" label="Country" sortable={false} />
                <FunctionField
                    label="Members"
                    render={(record: any) => {
                        const numberOfMembers = record.members.length;
                        return <span>{numberOfMembers}</span>;
                    }}
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};
