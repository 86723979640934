import { ResourceResponseDTO, ResourceListResponseDTO } from "./resource.dto";
import { FetchHttpFactory } from "../../http";
import { ListQs } from "../../types";

const fetchHttpFactory = new FetchHttpFactory();
const http = fetchHttpFactory.createHttp("/admin");

export const getOne = (resource: string, id: string) =>
    http.get<ResourceResponseDTO>(`/${resource}/${id}`);

export const getList = (resource: string, params: ListQs) => {
    return http.get<ResourceListResponseDTO>(`/${resource}`, params);
};

export const getMany = async (
    resource: string,
    ids: string[]
): Promise<ResourceResponseDTO> => {
    const chunkSize = 10;
    const result = [];

    for (let i = 0; i < ids.length; i += chunkSize) {
        const chunk = ids.slice(i, i + chunkSize);
        const response = await http.get<ResourceResponseDTO>(`/${resource}`, {
            filter: { ids: chunk },
            page: 0,
            perPage: 20 // To avoid pagination
        });

        if (!response.ok) {
            return { ok: false, error: response.error, data: undefined };
        }

        result.push(...response.data);
    }

    return { ok: true, data: result, error: undefined };
};

// @TODO: Implement getManyReference on API
export const getManyReference = (
    resource: string,
    target: string,
    id: string,
    params: ListQs
) => {
    const query = {
        ...params,
        filter: { ...params.filter, [target]: id }
    };

    return http.get<ResourceListResponseDTO>(`/${resource}`, query);
};

export const create = (resource: string, data: any) =>
    http.post<ResourceResponseDTO>(`/${resource}`, data);

export const update = (resource: string, id: string, data: any) =>
    http.patch<ResourceResponseDTO>(`/${resource}/${id}`, data);

export const updateMany = async (
    resource: string,
    ids: string[],
    data: any
) => {
    let ok = true;
    const returnedData = [];
    const errors = [];
    for (const id of ids) {
        const {
            ok: currentOk,
            data: posted,
            error
        } = await update(resource, id, data);

        if (!currentOk) {
            ok = false;
            errors.push(error);
        }

        returnedData.push(posted);
    }

    return { ok, data: returnedData, errors: errors };
};

export const remove = (resource: string, id: string) =>
    http.delete<ResourceResponseDTO>(`/${resource}/${id}`);

export const removeMany = async (resource: string, ids: string[]) => {
    let ok = true;
    const returnedData = [];
    const errors = [];
    for (const id of ids) {
        const {
            ok: currentOk,
            data: posted,
            error
        } = await remove(resource, id);

        if (!currentOk) {
            ok = false;
            errors.push(error);
        }

        returnedData.push(posted);
    }

    return { ok, data: returnedData, errors: errors };
};
