import { DeleteWithConfirmButton, SaveButton, Toolbar } from "react-admin";

export const EditToolbar = ({ isSuperAdmin }: { isSuperAdmin: boolean }) => (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <SaveButton size="medium" />
        {isSuperAdmin && (
            <DeleteWithConfirmButton
                size="medium"
                confirmTitle="Are you sure? This action is irreversible!"
                confirmContent=""
                confirmColor="warning"
            />
        )}
    </Toolbar>
);
