import { required, SelectInput } from "react-admin";

import { DefaultTextInput } from "components/default-text-input";
import { memberRoleOptions } from "constants/memberRoleOptions";
import { useFormContext } from "react-hook-form";

export const RoleSection = () => {
    const { watch } = useFormContext();
    const role = watch("role");

    return (
        <>
            <SelectInput
                source="role"
                choices={memberRoleOptions}
                optionValue="name"
            />
            {role === "Other" && (
                <DefaultTextInput
                    source="roleDescription"
                    validate={required("The role description is required")}
                />
            )}
        </>
    );
};
