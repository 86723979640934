import { GradientPageWrapper } from "styled-components/GradientPageWrapper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { PaperFormWrapper } from "styled-components/PaperFormWrapper";
import { ThemeProvider } from "react-admin";
import { customRadiantLightTheme } from "themes/radiantLightTheme";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { EMPTY_HELPER_TEXT, SNACKBAR_SCREEN_TIME } from "constants/general";
import { useForgotPassword } from "./useForgotPassword";

const ForgotPassword = () => {
    const {
        errors,
        handleOnSnackbarClose,
        handleSubmit,
        isSomethingWrong,
        register
    } = useForgotPassword();

    return (
        <GradientPageWrapper>
            <ThemeProvider theme={customRadiantLightTheme}>
                <Snackbar
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                    open={isSomethingWrong}
                    autoHideDuration={SNACKBAR_SCREEN_TIME}
                    onClose={handleOnSnackbarClose}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: "100%" }}
                    >
                        Something went wrong...
                    </Alert>
                </Snackbar>
                <PaperFormWrapper>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px"
                        }}
                    >
                        <TextField
                            type="text"
                            label="Email *"
                            helperText={
                                errors.email?.message || EMPTY_HELPER_TEXT
                            }
                            autoComplete="email"
                            {...register("email")}
                            error={Boolean(errors.email)}
                        />
                        <Button variant="contained" type="submit">
                            Send
                        </Button>
                    </Box>
                </PaperFormWrapper>
            </ThemeProvider>
        </GradientPageWrapper>
    );
};

export default ForgotPassword;
