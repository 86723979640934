import { useRecordContext } from "react-admin";

export const PageTitle = ({
    generatePageTitle
}: {
    generatePageTitle: (record: any) => string;
}) => {
    const record = useRecordContext();
    const title = generatePageTitle(record);
    return <>Editing "{title}"</>;
};
