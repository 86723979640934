import { useState } from "react";

export const useCopyToClipboardButton = () => {
    const [isSnackbarOpened, setIsSnackbarOpened] = useState(false);

    const handleSnackbarOpen = () => {
        setIsSnackbarOpened(true);
    };

    const handleSnackbarClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setIsSnackbarOpened(false);
    };

    const handleCopyTextToClipboard = (textToCopy: string) => {
        navigator.clipboard.writeText(textToCopy);
        handleSnackbarOpen();
    };

    return { isSnackbarOpened, handleSnackbarClose, handleCopyTextToClipboard };
};
